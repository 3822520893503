import React from 'react'
import { TrackedButton } from '@flock/shared-ui'
import { Grid, Typography, Box } from '@mui/material'
import Fade from 'react-reveal/Fade'
import {
  FAQ_PATH,
  ONBOARDING_PATH,
  PORTFOLIO_PATH,
} from '@flock/shared-ui/src/routeConstants'
import ComputerImage from '../../images/what-is-flock-computer-mobile.webp'
import CoupleImage from '../../images/what-is-flock-couple-mobile.webp'
import FundImage from '../../images/what-is-flock-fund-mobile.webp'
import SectionLayout from '../SharedComponents/SectionLayout'
import InlineMessageAddress from '../SharedComponents/InlineMessageAddress'
import { WebinarModal } from '../SharedComponents/WebinarModal'

const WhatIsFlockComponentMobile = () => (
  <>
    <SectionLayout
      name="What-Is-Flock-Section-Row-1"
      backgroundColor="trustBlue.main"
      verticalPadding="0px"
    >
      <Grid item xs={12} sm={12} md={6}>
        <Typography
          pt="32px"
          variant="h1"
          textAlign="left"
          color="moneyGreen.main"
        >
          Easier than owning. Better than selling.
        </Typography>
      </Grid>
    </SectionLayout>
    <SectionLayout
      name="What-Is-Flock-Section-Row-1"
      backgroundColor="trustBlue.main"
    >
      <Grid item xs={12} sm={12} md={6}>
        <Fade duration={500} distance="5rem" left>
          <Box display="flex" justifyContent="center">
            <Box display="flex" alignItems="center" height="100%">
              <Box display="flex" justifyContent="center">
                <img
                  src={FundImage}
                  alt="Flock App Preview"
                  style={{
                    width: '304px',
                    height: '201px',
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Fade>
      </Grid>
      <Grid item xs={12} sm={12} md={5} display="flex" alignItems="center">
        <Fade duration={500} distance="5rem" right>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            gap={{ xs: '16px', sm: '32px' }}
          >
            <Typography variant="h2" textAlign="left" color="moneyGreen.main">
              Your house enters the Flock fund.
            </Typography>
            <Typography variant="p1" textAlign="left" color="gray8.main">
              When you join Flock, your property is added to our portfolio of
              homes across the country. These assets vary in terms of price
              point, yield, and appreciation potential, creating a diversified
              investment that decreases the risk of owning one individual house.
            </Typography>
            <Grid xs={8} md={8} item>
              <TrackedButton
                to={PORTFOLIO_PATH}
                variant="secondary"
                size="small"
                sx={{
                  display: 'block',
                }}
              >
                See our Portfolio
              </TrackedButton>
            </Grid>
          </Box>
        </Fade>
      </Grid>
    </SectionLayout>
    <SectionLayout
      name="What-Is-Flock-Section-Row-2"
      backgroundColor="trustBlue.main"
    >
      <Grid item xs={12} sm={12} md={6} order={{ sm: 1, xs: 1, md: 3 }}>
        <Fade duration={500} distance="5rem" right>
          <Box display="flex" justifyContent="center">
            <Box display="flex" alignItems="center" height="100%">
              <Box display="flex" justifyContent="center">
                <img
                  src={CoupleImage}
                  alt="Older Couple"
                  style={{
                    width: '304px',
                    height: '201px',
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Fade>
      </Grid>
      <Grid
        item
        xs={12}
        md={5}
        order={{ sm: 2, xs: 2, md: 1 }}
        display="flex"
        alignItems="center"
      >
        <Fade duration={500} distance="5rem" left>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            gap={{ xs: '16px', sm: '32px' }}
          >
            <Typography variant="h2" textAlign="left" color="moneyGreen.main">
              You have ownership of a portfolio of rentals.
            </Typography>
            <Typography variant="p1" textAlign="left" color="gray8.main">
              Flock brings institutional-caliber expertise to individual owners
              like you. It&apos;s hard to generate outsized returns if owning
              rental property is your part-time job, but our team of real estate
              professionals provide industry expertise and economies of scale
              for your benefit.
            </Typography>
            <Grid md={6} xs={7} item>
              <TrackedButton
                to={FAQ_PATH}
                variant="secondary"
                size="small"
                sx={{
                  display: 'block',
                }}
              >
                See our FAQ
              </TrackedButton>
            </Grid>
          </Box>
        </Fade>
      </Grid>
    </SectionLayout>
    <SectionLayout
      name="What-Is-Flock-Section-Row-3"
      backgroundColor="trustBlue.main"
    >
      <Grid item xs={12} sm={12} md={6}>
        <Fade duration={500} distance="5rem" left>
          <Box display="flex" justifyContent="center">
            <img
              src={ComputerImage}
              alt="Flock App Preview"
              style={{
                width: '304px',
                height: '201px',
              }}
            />
          </Box>
        </Fade>
      </Grid>
      <Grid item xs={12} sm={12} md={5} display="flex" alignItems="center">
        <Fade duration={500} distance="5rem" right>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            gap={{ xs: '16px', sm: '32px' }}
          >
            <Typography variant="h2" textAlign="left" color="moneyGreen.main">
              Your client experience.
            </Typography>
            <Typography variant="p1" textAlign="left" color="gray8.main">
              With Flock, we&apos;ve designed a product that allows you to make
              your transition to passive ownership...
            </Typography>
            <Typography variant="p1" textAlign="left" color="gray8.main">
              Exchange your property for shares once, and receive passive income
              forever!
            </Typography>
            <Grid md={6} xs={7} item pb="16px">
              <TrackedButton
                to={ONBOARDING_PATH}
                variant="primary"
                size="small"
                sx={{
                  display: 'block',
                }}
              >
                Get started
              </TrackedButton>
            </Grid>
          </Box>
        </Fade>
      </Grid>
      <Grid item xs={12}>
        <InlineMessageAddress
          text="Landlords, retire and minimize taxes."
          variant="button"
          label="Get Started"
        />
      </Grid>
      <Grid item xs={12} textAlign="end" pt="16px">
        <WebinarModal />
      </Grid>
    </SectionLayout>
  </>
)

export default WhatIsFlockComponentMobile
