import {
  AddressData,
  createLeadHelper,
  localStore,
  UserEventType,
} from '@flock/utils'
import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { useMutation } from '@apollo/client'
import { InlineMessage } from '@flock/shared-ui/src/components/InlineMessage'
import {
  Core_TransactionType,
  LandingCreateLeadDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { useTracking } from '@flock/shared-ui'
import { ONBOARDING_PATH } from '@flock/shared-ui/src/routeConstants'
import { GOOGLE_MAPS_API_KEY } from '../../constants'
import { getEventTokenUUID } from '../../utils/analytics'

export type InlineMessageAddressProps = {
  text: string
  variant: 'address' | 'button'

  label: string

  onClick?: () => void
  brokerUuid?: string
}

const InlineMessageAddress = (props: InlineMessageAddressProps) => {
  const { track } = useTracking()
  const { brokerUuid, variant } = props
  const [addressData, setAddressData] = useState<AddressData | null>(null)
  const [createLead] = useMutation(LandingCreateLeadDocument)
  const [loading, setLoading] = useState<boolean>(false)
  const onSubmit = async () => {
    setLoading(true)
    track('address-cta-click', {
      type: 'button',
      leadConversion: 'started',
      actionType: UserEventType.BUTTON_CLICK,
    })
    const { search } = window.location
    const params = new URLSearchParams(search)
    if (addressData?.streetAddress) {
      try {
        const leadData = {
          addressData,
          source: 'onboarding',
          transactionType: Core_TransactionType.TransactionTypeSingle_721,
          eventTokenUuid: getEventTokenUUID(),
        }
        const queryParams = localStore?.getItem('queryParams')
        let parsedQueryParams: any = {}
        if (queryParams) {
          parsedQueryParams = JSON.parse(queryParams)
        }
        const createLeadResponse = await createLeadHelper(
          leadData,
          parsedQueryParams,
          createLead
        )
        const newLeadUuid = createLeadResponse?.data.createLead.lead.uuid
        let path = `${ONBOARDING_PATH}?leadUuid=${newLeadUuid}`
        if (typeof Storage !== 'undefined') {
          localStore?.removeItem('step')
          localStore?.removeItem('flowData')
        }
        // carry forward all url params to be parsed in the same flow as onboarding flow
        params.forEach((key, value) => {
          path += `&${value}=${key}`
        })
        if (brokerUuid) {
          path += `&brokerUuid=${brokerUuid}`
        }
        navigate(path)
      } catch (e) {
        window.location.href = `https://form.jotform.com/tech700/flock-onboarding?address[addr_line1]=${addressData?.streetNumber}%20${addressData?.streetAddress}&address[city]=${addressData?.city}&address[state]=${addressData?.state}&address[postal]=${addressData?.zipcode}`
      }
    }
    setLoading(false)
  }

  return (
    <InlineMessage
      {...props}
      variant={variant}
      googleMapsApiKey={GOOGLE_MAPS_API_KEY || ''}
      onChange={(data: AddressData | null) => setAddressData(data)}
      onSubmit={onSubmit}
      to={ONBOARDING_PATH}
      loading={loading}
      trackingConfig={{
        properties: {
          leadConversion: 'started',
        },
      }}
    />
  )
}

export default InlineMessageAddress

InlineMessageAddress.defaultProps = {
  placeholder: '',
  label: '',
  brokerUuid: '',
}
